<template>
   
       
           <div class="row justify-content-center align-items-start g-2">
            <div class="col-2">
               <!-- Hover added -->
               <label >فرز حسب التاربخ <input type="checkbox" v-model="showFilter" ></label>
               <div class="list-group" v-if="showFilter">
                <label >
                    من تاريخ <input type="date" name="date" id="date" v-model="firstDate" />
                </label>
                <label >
                    الى تاريخ <input type="date" name="date" id="date" v-model="ToDate" />
                </label>
                
               </div>
            </div>
            <div class="col   " >
              
                <div id="content" style="height:100vh" class="table-responsive-sm overflow-auto">
                    <h5 class="text-center">ترتيب المستخدمين حسب السرعة </h5>
                    <table class="table table-striped-columns
                    table-hover	
                    table-bordered
                    table-secondary
                    align-middle">
                        <thead class="table-light text-center">
                          
                            <tr >
                                <th>الاسم</th>
                                <th>السرعة</th>
                            </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr v-for="key,i of Object.keys(users)"  :key="i" class="table-primary" >
                                    <td scope="row">{{users[key].userinfo[0].name}}</td>
                                    <td>{{users[key].speed.toFixed(2)}}</td>
                                   
                                </tr>
                               

                              
                            </tbody>
                          
                    </table>
                   
                </div>
              
                
            </div>
           
           </div>
</template>

<script>
    import jsPDF from "jspdf"
    export default {
           props:['msg'],
            data:()=>{
                return {
                    users:{},
                    data:{},
                    usersResults:{},
                    userSelectedData:{},
                    firstDate:new Date().toISOString().substring(0,10),
                    ToDate:new Date().toISOString().substring(0,10),
                    showFilter:false,
                    max_speed:1,
                    counter:0,
                    
                }
            },
            methods:{
                generatePdf(){


               
                    var doc = new jsPDF('p', 'pt',"A4" );
           


                
                   
                    doc.setLanguage('ar')
                    
                    doc.html(document.getElementById("content"), {
		callback: function(doc) {
			doc.save("output.pdf");
		},
		x: 10,
		y: 10
	});
                  
                  
                }
            },
            watch: {
                ToDate(newValue) {
                  
                    this.users=this.data.filter((user)=> user.userinfo[0].createAt<=(newValue) && user.userinfo[0].createAt>=this.firstDate);
            
                },
                firstDate(newValue) {
                  
                    this.users=this.data.filter((user)=> user.userinfo[0].createAt>=(newValue) && user.userinfo[0].createAt<=this.ToDate);
                   
                },
                showFilter(newValue){
                    if(!newValue){
                        this.users=this.data
                    }
                    else{
                        this.users=this.data.filter((user)=> user.userinfo[0].createAt>=this.firstDate && user.userinfo[0].createAt<=this.ToDate);
                    }
                }
            },
            computed: {
                divStyle() {

                   
                    return{
                        width:"10px"
                    }
                }
            },
         
              
           
            
            async beforeMount() {
                this.users =await this.$store.getters.results
                this.data=this.users
                
                this.max_speed=this.users[0].speed

    
}
           
    }
</script>

<style lang="scss" scoped>

</style>