<template>
   
<div v-if="boolshowForm" class="container-fluid bg-light w-50 rounded-2 " style="position:absolute; top:5em;z-index: 1000;">
   <div class="container">
    <form @submit.prevent>
        
            
                <div class="mb-3">
                  <label for="title" class="form-label text-medium">العنوان</label>
                  <textarea class="form-control" name="title" id="title" rows="5" v-model="editedFormTitle"></textarea>
                </div>
            
            <div v-if="!booleditLesson && !booladdLesson" class="mb-3">
              <label for="traintext" class="form-label">نص التدريب</label>
              <textarea class="form-control" name="traintext" id="traintext" rows="5" v-model="editedFormTrainText"></textarea>
            </div>
        <div class="mb-3 row">
            <div class="offset-sm-4 col-sm-8">
                <button type="submit" class="btn btn-success"  @click.prevent="saveLesson">حفظ</button>
                <button type="submit" class="btn btn-danger mx-3"  @click.prevent="saveLesson(false)">إلغاء</button>
            </div>
        </div>
    </form>
   </div>
</div>

        <div class="row justify-content-center align-items-start g-2">
            <div class="col-3 overflow-auto " style="height:100vh; scroll-behavior:smooth ;">
                <!-- Hover added -->
                <div class="list-group">
                    <!-- <button type="button" class="list-group-item list-group-item-action active" aria-current="true">Active item</button> -->
                    <button v-for="lesson,index of lessonsExcercises" :id="index" :key="index" type="button"
                        class="list-group-item list-group-item-action my-1 " @click="userSelectedEvent(index,$event)">{{lesson.title?lesson.title:lesson.lessonId}}</button>
                </div>
            </div>
            <div class="col overflow-auto px-3" style="height:100vh;">
                <div v-if="this.currentID>-1" >
               


                  
                <div v-for="lesson,i of lessonsExcercises[currentID].exercise" :key="i" class="card h-50 mb-1">
                    <div class="card-header">
                        <p> التمرين {{i+1}}</p>
                    </div>
                    <div class="card-body ">
                        <h5 class="card-title">{{lesson.title}}</h5>
                        <p class="card-text overflow-hidden">{{lesson.text}}</p>
                        <a class="btn btn-success mx-2" @click="openEditForm(false,i)">تعديل</a>
                        <a class="btn btn-danger" @click="deleteExercise(i)">حذف</a>
        
                    </div>
                </div>
            </div>
            </div>
            <div   class="col-3">
                <button type="button" class="btn btn-info " @click="openEditForm(true,-1)" >+ إضافة درس</button>
                <button v-if="currentID>-1" type="button" class="btn btn-danger  " @click="deleteLesson()" >- حذف الدرس</button>
                <button v-if="currentID>-1" type="button" class="btn btn-success " @click="openEditForm(true,0)" >* تعديل عنوان الدرس</button>
                <button v-if="currentID>-1" type="button" class="btn btn-info " @click="openEditForm(true,-2)" >+ إضافة تمرين</button>
 
             
            </div>
        </div>
        

</template>

<script>


import DBservice from '../../DBService'


    export default {
           props:['msg'],
            data:()=>{
                return {
                   
                    lessonsExcercises:null,

                    editedFormTitle:'',
                    editedFormTrainText:'',
                    
                    currentID:-1,
                    exercId:'',
                    
                    boolshowForm:false,
                    booleditLesson:false,
                    booladdLesson:false
                }
            },
          
            methods: {
                userSelectedEvent(index,event) {

                 //change button class to be active
                 const activeButton=document.querySelectorAll('div.list-group>button.active')
                 if(activeButton.length!=0)
                    activeButton[0].classList.remove('active')
                 event.target.classList.add("active")

                  //fetch user data
                  this.currentID=index
               

                },
               async saveLesson(saveCommand){
                    if(!saveCommand)
                    {
                        this.boolshowForm=false;
                        return
                    }

                    this.$store.state.lessons=null
                    if(this.booleditLesson){
                        this.lessonsExcercises[this.currentID].title=this.editedFormTitle
                        await DBservice.updateLesson(this.lessonsExcercises[this.currentID]._id,this.editedFormTitle,null)
                        this.booleditLesson=false
                    }
                    else
                    if(this.booladdLesson){
                        this.boolshowForm=false
                        await DBservice.addLesson({title:this.editedFormTitle,id:this.lessonsExcercises[this.lessonsExcercises.length-1].lessonId+1},true)
                        this.lessonsExcercises=await this.$store.getters.lessons 
                        this.booladdLesson=false     
                      
                        
                        
                    }
                    else
                    if(this.exercId>-1)
                    {
                       
                        this.lessonsExcercises[this.currentID].exercise[this.exercId].title=this.editedFormTitle;
                        this.lessonsExcercises[this.currentID].exercise[this.exercId].text=this.editedFormTrainText;
                      
                        DBservice.updateLesson(this.lessonsExcercises[this.currentID]._id,
                                                this.exercId,    
                                                this.lessonsExcercises[this.currentID].exercise[this.exercId])
                        //update lesson in data base
                       
                  
                   }
                    else{
                       
                        const exercise={
                            title:this.editedFormTitle,
                            text:this.editedFormTrainText,
                            _id:this.lessonsExcercises[this.currentID]._id
                        }
                        this.lessonsExcercises[this.currentID].exercise.push(exercise)
                        DBservice.addLesson( exercise,false)

                        
                        

                    }

                    this.boolshowForm=false;
                    
                  
                   
                  
                   
                },
                openEditForm(add,index){
                
                    if(!add)
                    {
                        
                        this.editedFormTitle=this.lessonsExcercises[this.currentID].exercise[index].title;
                        this.editedFormTrainText=this.lessonsExcercises[this.currentID].exercise[index].text;
                        this.exercId=index;
                        this.booleditLesson=false
                        this.booladdLesson=false
                        
                    }
                    else if(index==-2)
                    {
                        this.booleditLesson=false
                        this.booladdLesson=false
                        this.editedFormTitle=""
                        this.editedFormTrainText=""
                        this.exercId=-1;
                        
                    }
                    else if(index==-1){
                       
                        this.booladdLesson=true

                    }
                    else{
                        this.booleditLesson=true   
                        this.editedFormTitle=this.lessonsExcercises[this.currentID].title
                    }
                    this.boolshowForm=true;
                },
                async deleteExercise(index){

                    DBservice.deletlesson(this.lessonsExcercises[this.currentID]._id,index)
                    this.lessonsExcercises[this.currentID].exercise= this.lessonsExcercises[this.currentID].exercise.filter((v,i)=>
                   {
                        return i!=index
                   })
                   this.$store.state.lessons=null
                 

                },
                async deleteLesson(){

                    DBservice.deletlesson(this.lessonsExcercises[this.currentID]._id,-1)
                    this.lessonsExcercises= this.lessonsExcercises.filter((v,i)=>
                   {
                        return i!=this.currentID
                   })
                   this.currentID=-1

                   this.$store.state.lessons=null
                  
                }
                
            }
            ,
            async beforeMount() {
           
             this.lessonsExcercises=await this.$store.getters.lessons
            
            

          
                
    },
           
    }

  
</script>

<style lang="css" scoped>
    *{
        font-size: small;
    }
</style>