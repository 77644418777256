import { createStore } from 'vuex'
import DBservice from '../DBService'

export default createStore({
  state () {
    return {
      results: null,
      lessons:null,
      articales:null,
      users:null,
      user:null
    }
  },
 mutations:{
    user(state,value){
        state.user=value
    }
 },
  getters:{
    async results(state){
      if(state.results==null)
        state.results=await DBservice.getresults()
      return state.results
    
    },
    async users(state){
      if(state.users==null)
      {
        state.users=await DBservice.getAllusers()
      }

      return state.users
    }
    ,
    async articales(state){
      if(state.articales==null)
        state.articales=await DBservice.getAllArticales()
      return state.articales
      
    },
    async lessons(state){
      if(state.lessons==null)
        state.lessons=await DBservice.getLesson(0)
       
      return state.lessons
    }
    ,
    isAdmin(state){
        return state.user!=null && state.user.admin
    },
     async user(state){
       
         
          if(localStorage.getItem('token')!=null)
          {
            if(state.user!=null)
               return {"_id":state.user._id,"name":state.user.name}
           await DBservice.getAllusers().then((resultUsers)=>{
           
            resultUsers.map((u)=>{
              if(u._id==localStorage.getItem('token'))
                state.user=u;
            })
           }
           )
           
           return state.user
          }
        return null
    }
  }
  
})
