<template>
    <div id="main" style="height:100vh; width:100% ;">
        <div id="menulist" style="height:100vh">
            <LessonsMenu  @lessonChanged="changeData" />
        </div>
    
        <div id="panel " style="background: white; padding: 0 5em; ">
            <!-- header point the current lesson and excercise -->
            <div class="row">
        
                <div class="card  bg-light ">
        
                    <div class="card-body p-0">
        
                        <p class="card-text text-success">
                            <span>الدروس &gt;</span><span v-if="id>0">الدرس {{id}}&gt; التطبيق {{exerciseId+1}}&gt;</span>
                        </p>
                    </div>
                </div>
        
        
        
        
        
            </div>
            <!--  -->

            <!-- texts before starting the excercise -->
            <div v-if="!starting">
                <!-- text to show -->
                <p><span v-for="line,i of welcomeText.split('\n')" :key="i">{{line}} <br /></span></p>
                <!-- jump buttons -->
                <div v-if="notStartTraining">

                    <button  @click="startTraining(false)" class="mx-1">إبدأ التمرين</button>
                    <button  @click="startTraining(true)">إبدأ من حيث انتهيت</button>
                    <!-- results when the training end -->
                </div>
                <div v-if="endTraining" class="table-responsive-sm">
                    <table class="table table-success ">
                        <thead>
                            <tr>
                                <th scope="col">رقم التمرين</th>
                                <th scope="col">الدقة</th>
                                <th scope="col">الزمن</th>
                                <th scope="col">السرعة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="result,index of curResultRegister" :key="index" class="">
                                <td scope="row">{{index+1}}</td>
                                <td>{{result.percision}}</td>
                                <td>{{result.time}}</td>
                                <td>{{result.speed}}</td>
                            </tr>
        
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>إجمالي النتيجة</th>
                                <td>{{totalResult.percision.toFixed(2)}}</td>
                                <td>{{totalResult.time.toFixed(2)}}</td>
                                <td>{{totalResult.speed.toFixed(2)}}</td>
                            </tr>
                        </tfoot>
                    </table>
        
                </div>
        
            </div>
        
            <TestCanvas v-else :trainText="trainText" :lessonId="id" @endTraining="updateResult" />
        </div>

        <div class="resutls  col " style="height:100vh" >
           <div class="row  mb-1 overflow-auto " style="height:48%; font-size: smaller;"> <TrainerResluts :result="currentUserResult" /></div>
            <div class="row mb-1 overflow-auto " style="height: 45%;">
                <Best10Users />
          </div>
        </div>
     
    </div>
   
</template>

<script >


import TrainerResluts from "./TrainerResluts.vue"
import LessonsMenu from "./LessonsMenu.vue"
import TestCanvas from "./TestCanvas.vue"
import DBservice from "../../DBService"
import Best10Users from "./Best10Users.vue"

export default {
   
    data:()=>{
        return {
            
            //lesson and excercise id
            id:-1,
            exerciseId:0,

            welcomeText:"",
            trainText:"",

            //current lesson data contain the lesson title and set of excercises
            currentLesson:null,

            //cuurent student result
            currentUserResult:{
                "#":"loading"
            },

            notStartTraining:false,
            endTraining:false,
           
            curResultRegister:[],
            totalResult:{percision:0,speed:0,time:0},

           
        }
    },
    components:{ TrainerResluts, LessonsMenu, TestCanvas, Best10Users }
   
   ,
    computed:{
        starting(){
            return this.trainText!=''
        }
    }
   ,
    methods:{

        // called To: Load the new lesson data or to moving to the next excercise
         async changeData(id){
           //there is new lesson
            if(id != null)
            {
                
                this.currentLesson=await this.$store.getters.lessons
                this.currentLesson= this.currentLesson[id]
               
                //initialize the result register
                this.curResultRegister=[];

                //save the lesson id and zeros  the excercise id
                this.id=id
                this.exerciseId=0
            }
         
            this.notStartTraining=true;
            this.endTraining=false;
            this.welcomeText=this.currentLesson.exercise[this.exerciseId].title;
            this.trainText='';
         }
         ,
         //called to active the trainText text to triger training operation
         startTraining(cont){
            //if the user choose to start from the last point he end at
            if(cont)
            {
                //get the excerise id from the result register
               this.exerciseId=this.currentUserResult.result[this.currentLesson.lessonId].pace || 0
               if(this.exerciseId!=0)
                    this.curResultRegister = this.currentUserResult.result[this.currentLesson.lessonId].tempResult || []
            }
            //deactivate welcome screen window and activate the training window
            this.welcomeText='';
            this.trainText=this.currentLesson.exercise[this.exerciseId].text;
         },

         //called when the user ends excercise training phase, get the training result to register it
         async updateResult(trainingResult){
           
            //it is the first time 
            if (this.currentUserResult['#']!=undefined)
            {

                //get the results for this user
                let data= await this.$store.getters.results
               let user=await this.$store.getters.user

                data=data.filter((uid)=>{
                   
                    return (uid.userId==user._id)
                })[0]
                
                //check if you did not find data for the user
                if(data===undefined || data===null || Object.keys(data.result).length==0)
                    this.currentUserResult={"#":"لم تقم بأي تدريب"}
                else
                    {
                        //setting the results to show
                        
                        let myresult={}
                        //fetching lesson name
                        let lessons=await this.$store.getters.lessons
                         lessons.forEach(element => {

                            
                            if(data.result[element.lessonId]!=undefined)
                                myresult[element.lessonId] ={...data.result[element.lessonId],lesson:element.title}
                            });
              
                        data.result=myresult   
                        this.currentUserResult=data
                        
                    }

            }
            else{

                //else: it is not the first time, and we have new data to load
                //push the result to 
                this.curResultRegister.push(trainingResult)
                this.exerciseId++
                let speed=0;
                if(this.exerciseId<this.currentLesson.exercise.length)
                    {
                     
                        this.changeData(null)
                    }
                else
                {
                    this.exerciseId=0;
                    this.welcomeText="انتهى التمرين"
                    this.notStartTraining=false;
                    this.endTraining=true
                    this.trainText=""
                  
                    for(let result of this.curResultRegister){
     
                        this.totalResult.percision+=Number.parseFloat(result.percision)/this.currentLesson.exercise.length
                        this.totalResult.speed+=Number.parseFloat(result.speed)/this.currentLesson.exercise.length
                        this.totalResult.time+=Number.parseFloat(result.time)/this.currentLesson.exercise.length
                    }
                   
                    this.currentUserResult[this.id]= this.totalResult;
                  
                    for (let key of Object.keys(this.currentUserResult.result)) {
                       
                       speed += this.currentUserResult.result[key].speed / Object.keys(this.currentUserResult.result).length
                    }


                    
                    
                   
                }
                //update results
                DBservice.updateMark(localStorage.getItem('userId'),{totalResult:this.totalResult,tempResult:this.curResultRegister,pace:this.exerciseId}, Number.parseInt(this.currentLesson.lessonId), speed)
                   

            }
        }
        }
    ,
    
     mounted () {
        this.$store.getters.results
        this.$store.getters.lessons
        this.$store.getters.articales
        this.$store.getters.user
        this.exerciseId=0;
     
        this.welcomeText=''
        this.updateResult();
      
        // this.lessons=["الدرس الأول","الدرس الثاني","الدرس الثالث","الدرس الرابع","الدرس الخامس",]
        // this.excersises=["التمرين الأول","التمرين الثاني","التمرين الثالث","التمرين الرابع","التمرين الخامس"]
    },
    updated(){
       
      
    }
}
</script>


<style>
    #main{
        display: grid;

        grid-template-columns: 1fr 4fr 1fr ;
        column-gap: 5em;
        height: 100vh;

    }
    #menulist{
        margin-bottom: 2em;
    }

   
</style>
