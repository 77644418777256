<template>
      
        <div class="lesson-menu " >  
            
           
           
            <ul class="lesson-menu-list">
                <button v-if="show" class="btn btn-primary" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Loading...
            </button>

         
                
                <li  v-for="lesson,i in data"  :key="i"  class="lesson-menu-item" @click="sendMessage(i)">
                <router-link   to=""> <a 
                      class="link not-active"><span class="status">•</span><span class="text">{{lesson.title}}</span></a></router-link>
           </li>
            </ul>
        </div>

</template>

<script>


export default {
    emits:["lessonChanged"],
   data:()=>{
    return {
        message:'menu',
        show:false,
        currentid:-1,
        data:null
    }
   },
   methods: {
    sendMessage(i) {
          
   
            this.$emit("lessonChanged",i)
        }
    
   },
   async mounted () {
        this.show=this.data==null
        this.data=await this.$store.getters.lessons
        this.show=this.data.length==0


   },
}
</script>

<style lang="css" scoped>

    *{
        font-size: small;
    }
   
    .lesson-menu{
        display: block;
      
        margin-top: 2em;

    }
    .lesson-menu-list{
        height:100vh;
        overflow-y: auto;
    }

    li{
        margin: 1em 0;
    }

   
</style>