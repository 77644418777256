<template>
    <div class="row">
        <div class="column medium-12">
            <div class="tb-practice-keyboard row">
                <div class="column small-2">
                    <div side="right" class="tb-hand" :finger="righthand"><svg
                            xmlns:svg="http://www.w3.org/2000/svg"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 200 253.5" id="svg2" version="1.1">
                            <defs id="defs4">
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7526" gradientUnits="userSpaceOnUse"
                                    gradientTransform="matrix(0.99579482,-0.67144317,0.67144317,0.99579482,-12375.712,-5638.0456)"
                                    x1="5824.8" y1="10247.7" x2="5824.4" y2="10312.6">
                                </linearGradient>
                                <linearGradient id="linearGradient7460">
                                    <stop offset="0" id="stop7462"
                                        style="stop-color: rgb(77, 181, 227); stop-opacity: 1;">
                                    </stop>
                                    <stop offset="1" id="stop7464"
                                        style="stop-color: rgb(77, 181, 227); stop-opacity: 0;">
                                    </stop>
                                </linearGradient>
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7522" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(-5371.7208,-9652.1804)"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8">
                                </linearGradient>
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7516" gradientUnits="userSpaceOnUse"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8"
                                    gradientTransform="translate(-5455.8457,-9666.1804)">
                                </linearGradient>
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7512" gradientUnits="userSpaceOnUse"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8"
                                    gradientTransform="translate(-5399.7208,-9673.0554)">
                                </linearGradient>
                                <linearGradient
                                    gradientTransform="translate(-5427.7207,-9680.3054)"
                                    xlink:href="#linearGradient7460" id="linearGradient7466"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8"
                                    gradientUnits="userSpaceOnUse"></linearGradient>
                            </defs>
                            <g id="layer1" transform="translate(-0.00816057,-825.63816)">
                                <g id="g4180"
                                    transform="matrix(1.3738728,0,0,1.3738728,-437.77308,5.0698826)">
                                    <path
                                        d="m396.6 598.3a10.5 10.5 0 0 0-10.5 10.5 10.5 10.5 0 0 0 0 0.1l0 66.6c0 1.9-1.6 3.5-3.5 3.5-1.9 0-3.5-1.6-3.5-3.5l0-52.6a10.5 10.5 0 0 0-10.5-10.5 10.5 10.5 0 0 0-10.5 10.5 10.5 10.5 0 0 0 0 0.1l0 87.6-14.2-19.4c-4.2-6.5-12.4-8.7-18.4-4.9-6 3.8-7.5 12.1-3.3 18.6 0 0 22.9 34.7 32.7 49.5 9.8 14.8 25.6 26.5 55.2 26.5 49 0 53.4-37.8 53.4-49.1 0-11.3 0-94.7 0-94.7a10.5 10.5 0 0 0-10.5-10.5 10.5 10.5 0 0 0-10.5 10.5l0 45.6c0 1.9-1.6 3.5-3.5 3.5-1.9 0-3.5-1.6-3.5-3.5l0-66.7a10.5 10.5 0 0 0-10.5-10.5 10.5 10.5 0 0 0-10.5 10.5l0 59.6c0 1.9-1.6 3.5-3.5 3.5-1.9 0-3.5-1.6-3.5-3.5l0-66.7a10.5 10.5 0 0 0-10.5-10.5z"
                                        id="path7455"
                                        style="fill-opacity: 1; fill: rgb(255, 255, 255); stroke-dasharray: none; stroke-miterlimit: 4; stroke-width: 2; stroke: rgb(204, 204, 204);">
                                    </path>
                                    <path id="finger-3"
                                        d="m396.6 597.3c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7466&quot;);">
                                    </path>
                                    <path
                                        d="m424.6 604.5c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        id="finger-2"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7512&quot;);">
                                    </path>
                                    <path
                                        d="m368.4 611.4c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        id="finger-4"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7516&quot;);">
                                    </path>
                                    <path id="finger-1"
                                        d="m452.6 625.4c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7522&quot;);">
                                    </path>
                                    <path
                                        d="m324.9 685.6c-6.3 4.3-8 12.9-3.7 19.2l0 0.1 15.5 22.7 22.9-15.5-15.6-22.8c-4.3-6.3-12.9-8-19.2-3.7z"
                                        id="finger-5"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7526&quot;);">
                                    </path>
                                </g>
                            </g>
                        </svg></div>
                </div>
                <div class="column small-8">
                    <div class="tb-keyboard">
                        <div class="tb-keyboard-row">
                            <span
                            :class="['tb-keyboard-key','undefined',('ّذ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ّ
                                </span> <span class="tb-keyboard-key-text">
                                    ذ
                                </span></span><span :class="['tb-keyboard-key','undefined',('!1'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    !
                                </span> <span class="tb-keyboard-key-text">
                                    ١
                                </span></span><span :class="['tb-keyboard-key','undefined',('2@'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    @
                                </span> <span class="tb-keyboard-key-text">
                                    ٢
                                </span></span><span :class="['tb-keyboard-key','undefined',('3#'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    #
                                </span> <span class="tb-keyboard-key-text">
                                    ٣
                                </span></span><span :class="['tb-keyboard-key','undefined',('4$'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    $
                                </span> <span class="tb-keyboard-key-text">
                                    ٤
                                </span></span><span :class="['tb-keyboard-key','undefined',('5%'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    %
                                </span> <span class="tb-keyboard-key-text">
                                    ٥
                                </span></span><span :class="['tb-keyboard-key','undefined',('^6'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ^
                                </span> <span class="tb-keyboard-key-text">
                                    ٦
                                </span></span><span :class="['tb-keyboard-key','undefined',('&7'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    &amp;
                                </span> <span class="tb-keyboard-key-text">
                                    ٧
                                </span></span><span :class="['tb-keyboard-key','undefined',('8*'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    *
                                </span> <span class="tb-keyboard-key-text">
                                    ٨
                                </span></span><span :class="['tb-keyboard-key','undefined',('9)'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    )
                                </span> <span class="tb-keyboard-key-text">
                                    ٩
                                </span></span><span :class="['tb-keyboard-key','undefined',('0('.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    (
                                </span> <span class="tb-keyboard-key-text">
                                    ٠
                                </span></span><span :class="['tb-keyboard-key','undefined',('_-'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    _
                                </span> <span class="tb-keyboard-key-text">
                                    -
                                </span></span><span :class="['tb-keyboard-key','undefined',('+='.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    +
                                </span> <span class="tb-keyboard-key-text">
                                    =
                                </span></span><span class="tb-keyboard-key backspace"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span></div>
                        <div class="tb-keyboard-row">
                            <span class="tb-keyboard-key tab"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span><span :class="['tb-keyboard-key','undefined',('ضَ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    َ
                                </span> <span class="tb-keyboard-key-text">
                                    ض
                                </span></span><span :class="['tb-keyboard-key','undefined',('ًص'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ً
                                </span> <span class="tb-keyboard-key-text">
                                    ص
                                </span></span><span :class="['tb-keyboard-key','undefined',('ثُ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ُ
                                </span> <span class="tb-keyboard-key-text">
                                    ث
                                </span></span><span :class="['tb-keyboard-key','undefined',('قٌ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ٌ
                                </span> <span class="tb-keyboard-key-text">
                                    ق
                                </span></span><span :class="['tb-keyboard-key','undefined',(char==='لإ'||char==='ف'?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    لإ
                                </span> <span class="tb-keyboard-key-text">
                                    ف
                                </span></span><span :class="['tb-keyboard-key','undefined',('إغ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    إ
                                </span> <span class="tb-keyboard-key-text">
                                    غ
                                </span></span><span :class="['tb-keyboard-key','undefined',('‘ع'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ‘
                                </span> <span class="tb-keyboard-key-text">
                                    ع
                                </span></span><span :class="['tb-keyboard-key','undefined',('÷ه'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ÷
                                </span> <span class="tb-keyboard-key-text">
                                    ه
                                </span></span><span :class="['tb-keyboard-key','undefined',('خ×'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ×
                                </span> <span class="tb-keyboard-key-text">
                                    خ
                                </span></span><span :class="['tb-keyboard-key','undefined',('ح؛'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ؛
                                </span> <span class="tb-keyboard-key-text">
                                    ح
                                </span></span><span :class="['tb-keyboard-key','undefined',('ج<'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    &lt;
                                </span> <span class="tb-keyboard-key-text">
                                    ج
                                </span></span><span :class="['tb-keyboard-key','undefined',('د>'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    &gt;
                                </span> <span class="tb-keyboard-key-text">
                                    د
                                </span></span><span class="tb-keyboard-key backslash"><span
                                    class="tb-keyboard-key-text-shift">
                                    |
                                </span> <span class="tb-keyboard-key-text">
                                    \
                                </span></span></div>
                        <div class="tb-keyboard-row">
                            <span class="tb-keyboard-key caps"><span class="tb-keyboard-key-text-shift">
                        
                                </span> <span class="tb-keyboard-key-text">
                        
                                </span></span>
                                
                                <span :class="['tb-keyboard-key','undefined',('شِ'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    ِ
                                </span> <span class="tb-keyboard-key-text">
                                    ش
                                </span></span>
                                
                                <span :class="['tb-keyboard-key','undefined',('سٍ'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    ٍ
                                </span> <span class="tb-keyboard-key-text">
                                    س
                                </span></span><span :class="['tb-keyboard-key','undefined',(']ي'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    ]
                                </span> <span class="tb-keyboard-key-text">
                                    ي
                                </span></span><span :class="['tb-keyboard-key','undefined',('ب['.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    [
                                </span> <span class="tb-keyboard-key-text">
                                    ب
                                </span></span><span :class="['tb-keyboard-key','undefined',(char==='ل' || char==='لأ'?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    لأ
                                </span> <span class="tb-keyboard-key-text">
                                    ل
                                </span></span>
                            <span :class="['tb-keyboard-key','undefined',('أا'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    أ
                                </span> <span class="tb-keyboard-key-text">
                                    ا
                                </span></span><span :class="['tb-keyboard-key','undefined',('تـ'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    ـ
                                </span> <span class="tb-keyboard-key-text">
                                    ت
                                </span></span><span :class="['tb-keyboard-key','undefined',('،ن'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    ،
                                </span> <span class="tb-keyboard-key-text">
                                    ن
                                </span></span><span :class="['tb-keyboard-key','undefined',('م/'.includes(char)?'next-key':'')]" ><span class="tb-keyboard-key-text-shift">
                                    /
                                </span> <span class="tb-keyboard-key-text">
                                    م
                                </span></span><span :class="['tb-keyboard-key','undefined',('ك:'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    :
                                </span> <span class="tb-keyboard-key-text">
                                    ك
                                </span></span><span :class="['tb-keyboard-key','undefined',('ط'.includes(char)?'next-key':'')]"><span class="tb-keyboard-key-text-shift">
                                    "
                                </span> <span class="tb-keyboard-key-text">
                                    ط
                                </span></span><span class="tb-keyboard-key enter"><span class="tb-keyboard-key-text-shift">
                        
                                </span> <span class="tb-keyboard-key-text">
                        
                                </span></span>
                        </div>
                        <div class="tb-keyboard-row">
                            <span
                                class="tb-keyboard-key shift-left"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span><span :class="['tb-keyboard-key','undefined',('~ئ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ~
                                </span> <span class="tb-keyboard-key-text">
                                    ئ
                                </span></span><span :class="['tb-keyboard-key','undefined',('ْء'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ْ
                                </span> <span class="tb-keyboard-key-text">
                                    ء
                                </span></span><span :class="['tb-keyboard-key','undefined',('}ؤ'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    }
                                </span> <span class="tb-keyboard-key-text">
                                    ؤ
                                </span></span><span :class="['tb-keyboard-key','undefined',('ر{'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    {
                                </span> <span class="tb-keyboard-key-text">
                                    ر
                                </span></span><span :class="['tb-keyboard-key','undefined',(char==='لا' || char==='لآ'?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    لآ
                                </span> <span class="tb-keyboard-key-text">
                                    لا
                                </span></span><span :class="['tb-keyboard-key','undefined',('آى'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    آ
                                </span> <span class="tb-keyboard-key-text">
                                    ى
                                </span></span><span :class="['tb-keyboard-key','undefined',('ة’'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ’
                                </span> <span class="tb-keyboard-key-text">
                                    ة
                                </span></span><span :class="['tb-keyboard-key','undefined',('و,'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ,
                                </span> <span class="tb-keyboard-key-text">
                                    و
                                </span></span><span :class="['tb-keyboard-key','undefined',('ز.'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    .
                                </span> <span class="tb-keyboard-key-text">
                                    ز
                                </span></span><span :class="['tb-keyboard-key','undefined',('ظ؟'.includes(char)?'next-key':'')]"><span
                                    class="tb-keyboard-key-text-shift">
                                    ؟
                                </span> <span class="tb-keyboard-key-text">
                                    ظ
                                </span></span><span
                                class="tb-keyboard-key shift-right"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span></div>
                        <div class="tb-keyboard-row">
                            <span
                                class="tb-keyboard-key ctrl"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span><span class="tb-keyboard-key alt"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span><span class="tb-keyboard-key space"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span><span class="tb-keyboard-key alt"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span><span class="tb-keyboard-key ctrl"><span
                                    class="tb-keyboard-key-text-shift">

                                </span> <span class="tb-keyboard-key-text">

                                </span></span></div>
                    </div>
                </div>
                <div class="column small-2">
                    <div :finger="lefthand" side="left" class="tb-hand"><svg
                            xmlns:svg="http://www.w3.org/2000/svg"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 200 253.5" id="svg2" version="1.1">
                            <defs id="defs4">
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7526" gradientUnits="userSpaceOnUse"
                                    gradientTransform="matrix(0.99579482,-0.67144317,0.67144317,0.99579482,-12375.712,-5638.0456)"
                                    x1="5824.8" y1="10247.7" x2="5824.4" y2="10312.6">
                                </linearGradient>
                                <linearGradient id="linearGradient7460">
                                    <stop offset="0" id="stop7462"
                                        style="stop-color: rgb(77, 181, 227); stop-opacity: 1;">
                                    </stop>
                                    <stop offset="1" id="stop7464"
                                        style="stop-color: rgb(77, 181, 227); stop-opacity: 0;">
                                    </stop>
                                </linearGradient>
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7522" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(-5371.7208,-9652.1804)"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8">
                                </linearGradient>
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7516" gradientUnits="userSpaceOnUse"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8"
                                    gradientTransform="translate(-5455.8457,-9666.1804)">
                                </linearGradient>
                                <linearGradient xlink:href="#linearGradient7460"
                                    id="linearGradient7512" gradientUnits="userSpaceOnUse"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8"
                                    gradientTransform="translate(-5399.7208,-9673.0554)">
                                </linearGradient>
                                <linearGradient
                                    gradientTransform="translate(-5427.7207,-9680.3054)"
                                    xlink:href="#linearGradient7460" id="linearGradient7466"
                                    x1="5825.3" y1="10261.1" x2="5825.3" y2="10341.8"
                                    gradientUnits="userSpaceOnUse"></linearGradient>
                            </defs>
                            <g id="layer1" transform="translate(-0.00816057,-825.63816)">
                                <g id="g4180"
                                    transform="matrix(1.3738728,0,0,1.3738728,-437.77308,5.0698826)">
                                    <path
                                        d="m396.6 598.3a10.5 10.5 0 0 0-10.5 10.5 10.5 10.5 0 0 0 0 0.1l0 66.6c0 1.9-1.6 3.5-3.5 3.5-1.9 0-3.5-1.6-3.5-3.5l0-52.6a10.5 10.5 0 0 0-10.5-10.5 10.5 10.5 0 0 0-10.5 10.5 10.5 10.5 0 0 0 0 0.1l0 87.6-14.2-19.4c-4.2-6.5-12.4-8.7-18.4-4.9-6 3.8-7.5 12.1-3.3 18.6 0 0 22.9 34.7 32.7 49.5 9.8 14.8 25.6 26.5 55.2 26.5 49 0 53.4-37.8 53.4-49.1 0-11.3 0-94.7 0-94.7a10.5 10.5 0 0 0-10.5-10.5 10.5 10.5 0 0 0-10.5 10.5l0 45.6c0 1.9-1.6 3.5-3.5 3.5-1.9 0-3.5-1.6-3.5-3.5l0-66.7a10.5 10.5 0 0 0-10.5-10.5 10.5 10.5 0 0 0-10.5 10.5l0 59.6c0 1.9-1.6 3.5-3.5 3.5-1.9 0-3.5-1.6-3.5-3.5l0-66.7a10.5 10.5 0 0 0-10.5-10.5z"
                                        id="path7455"
                                        style="fill-opacity: 1; fill: rgb(255, 255, 255); stroke-dasharray: none; stroke-miterlimit: 4; stroke-width: 2; stroke: rgb(204, 204, 204);">
                                    </path>
                                    <path id="finger-3"
                                        d="m396.6 597.3c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7466&quot;);">
                                    </path>
                                    <path
                                        d="m424.6 604.5c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        id="finger-2"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7512&quot;);">
                                    </path>
                                    <path
                                        d="m368.4 611.4c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        id="finger-4"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7516&quot;);">
                                    </path>
                                    <path id="finger-1"
                                        d="m452.6 625.4c-6.4 0-11.5 5.2-11.5 11.5l0 0.1 0 52.9 23 0 0-53c0-6.4-5.2-11.5-11.5-11.5z"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7522&quot;);">
                                    </path>
                                    <path
                                        d="m324.9 685.6c-6.3 4.3-8 12.9-3.7 19.2l0 0.1 15.5 22.7 22.9-15.5-15.6-22.8c-4.3-6.3-12.9-8-19.2-3.7z"
                                        id="finger-5"
                                        style="fill-opacity: 1; fill: url(&quot;#linearGradient7526&quot;);">
                                    </path>
                                </g>
                            </g>
                        </svg></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            char: {
                type: String,
                default: ""
            },
        },
        data() {
            return {
                righthand: "right-thumb",
                lefthand:"left-index",
                letters:require("./data/data")
            }
        },
        mounted(){
           
                
                const handclasses={right:"",left:""};
                for(let fingerType of  Object.keys(this.letters))
                {
                   
                   for(let key of Object.keys(this.letters[fingerType]))
                    {
                        if(key.includes(this.char))
                        {
                            handclasses[fingerType]=this.letters[fingerType][key]
                            break;
                        }
                    }
                }
                [this.righthand,this.lefthand]=[handclasses.right,handclasses.left];
               
            
        },
        watch:{
            char()
            {
                
                const handclasses={right:"",left:""};
                for(let fingerType of  Object.keys(this.letters))
                {
                   
                   for(let key of Object.keys(this.letters[fingerType]))
                    {
                        if(key.includes(this.char))
                        {
                            handclasses[fingerType]=this.letters[fingerType][key]
                            break;
                        }
                    }
                }
                [this.righthand,this.lefthand]=[handclasses.right,handclasses.left];
               
        }
    }
        ,
       updated(){

           
           
       }
    }
</script>

<style scoped>

</style>