<template>
   
       
           <div class="row justify-content-center align-items-start g-2">
            <div class="col-4">
               <!-- Hover added -->
               <div class="list-group overflow-scroll" style="height:100vh">
                <input type="date" name="date" id="date" v-model="createdate" >
                <!-- <button type="button" class="list-group-item list-group-item-action active" aria-current="true">Active item</button> -->
                <button v-for="user,index of users" :key="index" :id="index" type="button"
                    class=" text-center list-group-item list-group-item-action my-0 btn" @click="userSelectedEvent($event,index)">
                   <p> {{user.userinfo[0].name}}</p>
                </button>
               </div>
            </div>
            <div class="col">
                <div  v-if="currentId!=-1 && Object.keys( users[currentId].result).length!=0"  class="table-responsive-sm">
                    <table class="table table-striped-columns
                    table-hover	
                    table-bordered
                    table-secondary
                    align-middle">
                        <thead class="table-light">
                            <caption class="row justify-content-center">نتائج المستخدم {{users[currentId].userinfo[0].name}}</caption>
                            <tr>
                                <th>الدرس</th>
                                <th>الدقة</th>
                                <th>الزمن</th>
                                <th>السرعة</th>

                            </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr v-for="res,i of users[currentId].result"  :key="i" class="table-primary" >
                                    <td scope="row">{{res.lesson}}</td>
                                    <td>{{res.percision.toFixed(2)}}</td>
                                    <td>{{res.time.toFixed(2)}}</td>
                                    <td>{{res.speed.toFixed(2)}}</td>
                                   
                                </tr>
                              
                            </tbody>
                            <tfoot>
                                 <tr>
                                    <th>السرعة الإجمالية</th>
                                    <td colspan="3">{{users[currentId].speed.toFixed(2)}}</td>
                                </tr>
                            </tfoot>
                    </table>
                </div>
                <div v-else-if="currentId!=-1 " >
                    <div class="card text-center">
                      <div class="card-body">
                        <h4 class="card-title">نتائج المستخدم {{users[currentId].userinfo[0].name}}</h4>
                        <p class="card-text">لم يقم باختبارات حتى الان</p>
                      </div>
                    </div>
                </div>
                
            </div>
            <div class="col-2">

                <button v-if="currentId!=-1" class="btn btn-sm btn-danger"  @click.prevent="deleteUser">حذف المستخدم</button>
            </div>
           </div>
</template>

<script>
 import DBservice from '../../DBService'

    export default {
           props:['msg'],
            data:()=>{
                return {
                    users:{},
                    data:{},
                    currentId:-1,


                  
                    createdate:new Date().toISOString().substring(0,10)
                    
                }
            },
            watch: {
                createdate(newValue) {
                    this.users=this.data.filter((user)=> user.createAt<=(newValue));
                    this.currentId=-1
                    
                }
            },
            
            methods: {
                 deleteUser(){
                    DBservice.deletresult(this.users[this.currentId]._id)
                    DBservice.deletuser(this.users[this.currentId].userId)
                   
                    this.data=this.data.filter(user=>user.userId!=this.users[this.currentId].userId)
                    this.users=this.data
                    this.$store.state.users=null
                   
                },
                async userSelectedEvent(event,i) {
                 //change user class
                 const activeButton=document.querySelectorAll('div.list-group>button.active')
                 if(activeButton.length!=0)
                    activeButton[0].classList.remove('active')
                 event.target.classList.add("active")
               

                 this.currentId=i
                 //this.data = await this.$store.getters.users
            let data =this.users[this.currentId]
          
          let myresult={}
          //fetching lesson name
          let lessons=await this.$store.getters.lessons
              
              lessons.forEach(element => {

              
              if(data.result[element.lessonId]!=undefined)
                  myresult[element.lessonId] ={...data.result[element.lessonId],lesson:element.title}
              });
  
          this.users[i].result=myresult   

               
              
                }
            }
           
            ,
            async mounted() {
               
            //this.data = await this.$store.getters.users
            this.users =await this.$store.getters.results
            this.data=this.users
            
       

    },
           
    }
</script>

<style lang="scss" scoped>

</style>